<template>
	<div>
		<v-card
			class="pt-6 mb20 shadow-blue-not-openned"
			:class="{
				pa5: $vuetify.breakpoint.smAndDown,
				pa15: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<v-row :class="{ 'ortho-align-center': $vuetify.breakpoint.smAndDown }">
				<v-col md="4" cols="12">
					<h1>{{ whoAlreadyDidExercices() }}%</h1>
				</v-col>
				<v-col md="8" cols="12" class="pt-0 pt-md-2 pb-0 pb-md-2">
					<h3>Des utilisateurs</h3>
					<p>Ont déjà fait leur session d'aujourd'hui</p>
				</v-col>
			</v-row>
			<v-col
				md="12"
				cols="12"
				class="text-center"
				v-if="formationData.formation_finished != true && formationData.last_exercice != null"
			>
				<span v-if="isTodayExerciceDone == true">
					<div v-if="didHeSucceed() == true">
						<span>🎉 Bravo, prochaine session débloquée !</span>
						<div
							v-if="formationData.infinity_exercice == true"
							class="animate__animated animate__pulse animate__infinite ortho-start-session mt-4"
							style="width: 100%"
							@click="todayExercice()"
						>
							<strong>Session suivante</strong>
						</div>
					</div>
					<div v-else>
						<span>💪 Tu peux faire mieux !</span>
						<div
							class="animate__animated animate__pulse animate__infinite ortho-start-session mt-4"
							style="width: 100%"
							@click="todayExercice()"
						>
							<strong>Refaire ma session du jour</strong>
						</div>
					</div>
				</span>
				<div v-else-if="formationData.last_exercice_date != null" class="ortho-button-exercice-container">
					<div class="animate__animated animate__pulse animate__infinite ortho-start-session" @click="todayExercice()">
						<strong>Faire ma session du jour</strong>
					</div>
				</div>
			</v-col>
		</v-card>
		<loading :show="loading_show" :label="loading_label"> </loading>
	</div>
</template>

<script>
import loading from 'vue-full-loading';
import { fakePercentUserDidExercise } from '@/utils/fake.js';

import { checkIfTodayExerciceIsDone } from '@/utils/exercise.js';

export default {
	name: 'TodayExercice',
	props: {
		formationData: Object,
		exercicesDisplay: Array,
	},
	data() {
		return {
			loading_show: false,
			loading_label: '3, 2, 1...',
			loading_timeOut: 2000,
		};
	},
	components: {
		loading,
	},
	computed: {
		isTodayExerciceDone() {
			return checkIfTodayExerciceIsDone(this.formationData);
		},
	},
	methods: {
		didHeSucceed() {
			for (var i = 0; i < this.exercicesDisplay.length; i++) {
				if (this.formationData.last_exercice._id == this.exercicesDisplay[i].exercices_id._id) {
					//nouvel exercice non debloqué (next exercice id is already in exercicesDoneData)
					return false;
				}
			}
			return true;
		},
		whoAlreadyDidExercices() {
			return fakePercentUserDidExercise();
		},

		todayExercice() {
			this.loading_show = true;
			const urlTarget = this.formationData._id + '/' + this.formationData.last_exercice._id;

			setTimeout(() => {
				this.loading_show = false;
				return this.$router.push('/exercices/' + urlTarget);
			}, this.loading_timeOut);
		},
	},
};
</script>

<style></style>
