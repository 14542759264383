<template>
	<v-card
		class="shadow-blue-not-openned"
		:class="{
			'pa10 mb20': $vuetify.breakpoint.smAndDown,
			'pa15 mb20': $vuetify.breakpoint.mdAndUp,
		}"
	>
		<h2 class="mb20">Dernières astuces</h2>
		<div v-for="(rule, index) in rulesUser.slice(0, 3)" :key="index">
			<v-row class="rulePresentation">
				<div>
					<p class="greyLight mb-0">{{ rule.name | tronc }}</p>
				</div>
				<div>
					<p
						class="font-weight-bold ortho-text-green-flash mb-0 d-flex justify-end align-center"
						:class="{
							greenText: rule.score < 150,
							goldText: rule.score == 150,
						}"
					>
						<v-icon class="iconsRules mr-1" color="#34e5c8" v-if="rule.score < 150">mdi-checkbox-marked-circle</v-icon>
						<v-icon class="iconsRules mr-1" color="#FFD15E" v-if="rule.score == 150">mdi-star</v-icon>
						{{ rule.score }}
						<img class="imgRules" v-if="rule.score < 150" src="@/assets/icons/lightening-green.svg" />
						<img class="imgRules" v-if="rule.score == 150" src="@/assets/icons/lightening-gold.svg" />/
						{{ rule.pointTarget }}
					</p>
				</div>
				<v-progress-linear
					:value="(rule.score / rule.pointTarget) * 100"
					height="6"
					:color="colorRules(rule.score)"
					class="progess-bar-score-rules"
					rounded
					background-color="#F1F1F1"
				></v-progress-linear>
			</v-row>
		</div>
		<div class="cta-div">
			<a
				:href="
					$router.resolve({
						name: 'Rules',
						params: {
							idFormation: formationData._id,
						},
					}).href
				"
				class="fs12 ortho-bold ortho-text-gray to-rule-page-href d-flex cta"
				style="display: block; margin: 0 auto; width: fit-content; text-align: center"
				@click="goToRulesPage()"
				>ESPACE RÈGLES <v-icon class="mr3 arrow-right" color="#979FA9" small>mdi-arrow-right</v-icon></a
			>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'RulesProfile',
	props: {
		rulesUser: Array,
		formationData: Object,
	},
	filters: {
		tronc(value) {
			if (value.length > 15) {
				return value.slice(0, 15) + '...';
			} else {
				return value;
			}
		},
	},
	methods: {
		colorRules(value) {
			if (value < 150) {
				return '#34e5c8';
			} else if (value == 150) {
				return '#FFD15E';
			} else {
				return '#34e5c8';
			}
		},
		goToRulesPage() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-profile-goToRulesPage');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
$greyLight: rgba(0, 0, 0, 0.54);

a {
	color: $greyLight;
	text-decoration: none;
}

.cta-div {
	&:hover {
		.cta {
			color: #686bdf !important;
		}
		.arrow-right {
			color: #686bdf !important;
			transform: translateX(30%);
		}
	}
}

.greyLight {
	color: $greyLight;
}
.greenText {
	color: #34e5c8;
}
.goldText {
	color: #ffd15e;
}

.to-rule-page-href {
	font-family: 'Lato';
}

.rulePresentation {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 15px;
	padding: 10px 12px 10px 12px;

	p:first-child {
		font-weight: bold;
		text-transform: uppercase;
		width: 100%;
	}
	p:nth-child(2) {
		width: fit-content;
		text-align: end;
	}
}
.iconsRules {
	font-size: 20px;
}
.imgRules {
	width: 14px;
}
.progess-bar-score-rules {
	margin-top: 10px;
}

@media only screen and (max-width: 1300px) {
	.rulePresentation {
		display: flex;
		flex-direction: column;
	}
}
</style>
