<template>
	<div>
		<v-card class="shadow-blue-not-openned mt20 pt-4 pb-4 pl-3 pr-3 pt-md-9 pb-md-9 pl-md-12 pr-md-12 mb-10">
			<div class="d-flex rulebadge">
				<div v-if="infosRules.score >= 100 && infosRules.score < 150">
					<v-tooltip right color="black">
						<template v-slot:activator="{ on, attrs }">
							<v-chip label small color="#2dc7ae" v-bind="attrs" v-on="on">
								<v-icon color="white" class="mr-1" small>mdi-checkbox-marked-circle</v-icon
								><span class="status-rulebadge">MAÎTRISÉE</span>
							</v-chip>
						</template>
						<span>Tu maîtrises cette règle, ton score supérieur à 100</span>
					</v-tooltip>
				</div>
				<div v-else-if="infosRules.score == 150">
					<v-tooltip right color="black">
						<template v-slot:activator="{ on, attrs }">
							<v-chip label small color="#FFD15E" v-bind="attrs" v-on="on">
								<v-icon color="white" class="mr-1" small>mdi-star</v-icon
								><span class="status-rulebadge">MÉMORISÉE</span>
							</v-chip>
						</template>
						<span>Tu as mémorisé cette règle, ton score est = 150</span>
					</v-tooltip>
				</div>
				<div v-else>
					<v-tooltip top color="black">
						<template v-slot:activator="{ on, attrs }">
							<v-chip v-bind="attrs" v-on="on" label small color="black" outlined> EN COURS DE MAÎTRISE </v-chip>
						</template>
						<span>Atteint 100 points pour maitriser cette règle</span>
					</v-tooltip>
				</div>
			</div>

			<div class="mt-2 mb-5 d-flex flex-column flex-md-row justify-space-between align-center">
				<h1 class="font-weight-bold text-uppercase mb-4 mb-md-0" :id="'rule-' + infosRules.id">
					{{ infosRules.name }}
				</h1>
				<div class="div-points-rule">
					<div class="d-flex align-center mb-2 justify-end">
						<p
							:class="{
								greenText: infosRules.score < 150,
								goldText: infosRules.score == 150,
							}"
							class="font-weight-bold mb-0 d-flex justify-end align-center scoreRule"
						>
							<span class="fs30">{{ infosRules.score }}</span>
							<img class="iconsRules" v-if="infosRules.score < 150" src="@/assets/icons/lightening-green.svg" />
							<img class="iconsRules" v-else src="@/assets/icons/lightening-gold.svg" />
							<span class="fs30">/{{ infosRules.pointTarget }} </span>
						</p>
					</div>

					<v-progress-linear
						:value="(infosRules.score / infosRules.pointTarget) * 100"
						height="10"
						:color="colorRules(infosRules.score)"
						class="mb20"
						rounded
						background-color="#F1F1F1"
					></v-progress-linear>
				</div>
			</div>
			<h3 class="mb-4 text-uppercase text-md-left">L'astuce</h3>
			<p v-html="infosRules.description"></p>
			<div class="action-container">
				<v-btn
					class="training-question-btn"
					depressed
					dark
					:href="'/rules-training/' + idFormation + '/' + infosRules.id"
					@click="goToTrainingQuestions()"
				>
					<v-icon left dark>mdi-food</v-icon>
					Questions d'entraînement
				</v-btn>
				<div v-if="infosRules.ressourceLink" class="fiche-container">
					<v-btn class="fiche-icon" :href="infosRules.ressourceLink" target="_blank" outlined color="black">
						<v-icon left> mdi-eye</v-icon>
						Voir la fiche
					</v-btn>
					<!-- <v-btn class="fiche-icon download-link" fab dark small outlined color="black" @click="downloadRessource()">
						<v-icon> mdi-download </v-icon>
					</v-btn> -->
				</div>
			</div>
		</v-card>
		<RulesInfosSessions :infosRules="infosRules" @displayRules="displayRules" />
	</div>
</template>

<script>
import RulesInfosSessions from './RulesInfosSessions';
// import FileSaver from 'file-saver';

export default {
	name: 'RulesInfos',
	props: {
		infosRules: Object,
		idFormation: String,
	},
	components: {
		RulesInfosSessions,
	},
	methods: {
		colorRules(value) {
			if (value < 150) {
				return '#34e5c8';
			} else if (value == 150) {
				return '#FFD15E';
			} else {
				return '#FEFEFE';
			}
		},
		displayRules(value) {
			this.$emit('displayRuleFromSession', value);
		},
		goToTrainingQuestions() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('dashboard-rules-goToTrainingQuestions');
			}
		},
		// downloadRessource() {
		// 	FileSaver.saveAs(this.infosRules.ressourceLink, 'Fiche OrthographIQ - ' + this.infosRules.name);
		// },
	},
};
</script>

<style lang="scss" scoped>
.status-rulebadge {
	color: white;
	font-weight: bold;
}
.iconsRules {
	width: 20px;
}
.greenText {
	color: #34e5c8;
}
.goldText {
	color: #ffd15e;
}
.div-points-rule {
	width: 50%;
}
.rulebadge v-chip {
	cursor: none !important;
}
.action-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 30px;
}
.fiche-icon {
	margin-left: 10px;
}

@media screen and (max-width: 600px) {
	.div-points-rule {
		width: 100%;
	}
	.v-btn {
		display: flex;
	}
	.scoreRule {
		span {
			font-size: 25px !important;
		}
	}
	.rulebadge {
		align-items: center;
		justify-content: center;
	}
	.action-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;

		.training-question-btn {
			margin-bottom: 10px;
			width: 100%;
		}

		.fiche-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
		}
		.fiche-icon {
			margin-left: 0px;
			width: 100%;
		}
		.download-link {
			display: none;
		}
	}
}
</style>
