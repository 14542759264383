<template>
	<v-card class="shadow-blue-not-openned cardRules" :class="{ ruleSelectClass: rule.name == ruleSelected }">
		<div class="pl-3 pt-5 pr-3 cardRulesTitle">
			<h6 class="text-uppercase ortho-text-gray">{{ rule.familyTagName }}</h6>
			<v-icon class="iconsRules" color="#34e5c8" v-if="rule.score >= 100 && rule.score < 150"
				>mdi-checkbox-marked-circle</v-icon
			>
			<v-icon class="iconsRules" color="#FFD15E" v-if="rule.score == 150">mdi-star</v-icon>
		</div>
		<h3 class="font-weight-bold pl-3 pb-5 pr-3 text-uppercase">
			{{ rule.name }}
		</h3>
		<v-progress-linear
			:value="(rule.score / rule.pointTarget) * 100"
			height="5"
			:color="colorRules(rule.score)"
			class="mb20"
			background-color="#F1F1F1"
		></v-progress-linear>
	</v-card>
</template>

<script>
export default {
	name: 'RulesCards',
	props: {
		rule: Object,
		ruleSelected: String,
	},
	methods: {
		colorRules(value) {
			if (value < 150) {
				return '#34e5c8';
			} else if (value == 150) {
				return '#FFD15E';
			} else {
				return '#34e5c8';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.blockRules {
	.cardRules {
		cursor: pointer;
		&:hover {
			background: rgb(238, 238, 238);
		}
	}
	.cardRulesTitle {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		margin-bottom: 10px;
	}
	.iconsRules {
		width: 20px;
	}
}
.ruleSelectClass {
	background: rgb(238, 238, 238);
}
</style>
